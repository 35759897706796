import { useTriggerMobileWebModalStore } from "@/stores"
import React, { useEffect } from "react"
import Modal from "../Modal"
import { CheckCircleIcon } from "../shared/icons"
import { isMobileOnly } from "react-device-detect"
import { cdnPublicFolderUrl } from "@/constants"
import Link from "next/link"
import { useAuth } from "@/providers/authContext"

const AddToHomeScreenModal = () => {
  const { loading, user } = useAuth()
  const { isOpen, setIsOpen, onTrigger } = useTriggerMobileWebModalStore()

  useEffect(() => {
    if (loading) return
    if (!localStorage.getItem("add-to-home-screen")) {
      onTrigger()
    }
  }, [loading, user])

  const handleClose = () => {
    localStorage.setItem("add-to-home-screen", "true")
    setIsOpen(false)
  }

  if (!isMobileOnly) return null

  return (
    <Modal
      className="w-full rounded-none bg-[#E3E3E3] min-h-full p-0"
      bodyClassName="p-0"
      closeButtonClassName="bg-atherGray-800 absolute top-4 right-4 z-[2]"
      containerClassName="p-0 flex md:hidden"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <img
        src={`${cdnPublicFolderUrl}/images/tutorial/bg.png`}
        alt="Background"
        className="h-full object-contain absolute top-0 right-0"
      />
      <div className="flex flex-col items-center py-8 px-4 z-[1] text-atherGray-700">
        <Link
          href="/landing"
          shallow
          className="block"
          onClick={() => {
            handleClose()
          }}
        >
          <img className="mb-2" src={`${cdnPublicFolderUrl}/images/tutorial/logo.png`} alt="Logo" />
        </Link>
        <p className="text-xl font-semibold text-center">
          Add GAIA to Home Screen <br /> Get better experience on mobile!
        </p>
        <div className="w-full mt-16">
          <div>
            <div className="flex space-x-2 mb-2 items-center px-4">
              <div className="rounded-full text-atherGray-100 bg-atherPurple-500 w-8 h-8 flex items-center justify-center text-base font-semibold">
                1
              </div>
              <p className="text-base leading-5">
                On Safari browser
                <br />
                Push <span className="font-semibold">Share</span> button
              </p>
            </div>
            <img
              src={`${cdnPublicFolderUrl}/images/tutorial/1.png`}
              alt="Logo"
              className="rounded-2xl max-w-[16.5rem]"
            />
          </div>
          <div>
            <div className="flex space-x-2 mb-2 items-center px-4">
              <div className="rounded-full text-atherGray-100 bg-atherPurple-500 w-8 h-8 flex items-center justify-center text-base font-semibold">
                2
              </div>
              <p className="text-base leading-5">
                Scroll to <span className="font-semibold">Add to Home</span>
                <br />
                <span className="font-semibold">Screen</span> and click on it
              </p>
            </div>
            <img
              src={`${cdnPublicFolderUrl}/images/tutorial/2.png`}
              alt="Logo"
              className="rounded-2xl max-w-[16.5rem]"
            />
          </div>
          <div>
            <div className="flex space-x-2 mb-2 items-center px-4">
              <div className="rounded-full text-atherGray-100 bg-atherPurple-500 w-8 h-8 flex items-center justify-center text-base font-semibold">
                3
              </div>
              <p className="text-base leading-5">
                Click <span className="font-semibold">Add</span> button
              </p>
            </div>
            <img
              src={`${cdnPublicFolderUrl}/images/tutorial/3.png`}
              alt="Logo"
              className="rounded-2xl max-w-[16.5rem]"
            />
          </div>
          <div>
            <div className="flex space-x-2 mb-2 items-center px-4">
              <div className="rounded-full text-atherGray-100 bg-atherPurple-500 w-8 h-8 flex items-center justify-center text-base font-semibold">
                <CheckCircleIcon checked width={28} height={28} />
              </div>
              <p className="text-base leading-5 font-semibold">
                Woohoo! Enjoy GAIA as <br /> a native Webapp
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddToHomeScreenModal
